import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Using Big Mario to Grow The AAP Audience`}</h1>
    <p><strong parentName="p">{`We're testing a new format out today - taking you behind the scenes to see the
play-by-play of our slack conversation to figure out how to grow the AAP
newsletter audience`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1468px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "65.49999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHbuthIB//EABYQAAMAAAAAAAAAAAAAAAAAAAIQMf/aAAgBAQABBQIYhi//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAYEAADAQEAAAAAAAAAAAAAAAAAASEQEf/aAAgBAQABPyFpO5Ssuf/aAAwDAQACAAMAAAAQ88//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAdEAEAAgICAwAAAAAAAAAAAAABABEhMRBBUaGx/9oACAEBAAE/EEKcFaqHl8eKAYxqFjfqA9s//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Big Mario Marketing Slack 1",
          "title": "Big Mario Marketing Slack 1",
          "src": "/static/8b204cc15fbda12b0f232c7f741c4223/7b819/big-mario-slack-convo-1.jpg",
          "srcSet": ["/static/8b204cc15fbda12b0f232c7f741c4223/a6407/big-mario-slack-convo-1.jpg 400w", "/static/8b204cc15fbda12b0f232c7f741c4223/a24e6/big-mario-slack-convo-1.jpg 800w", "/static/8b204cc15fbda12b0f232c7f741c4223/7b819/big-mario-slack-convo-1.jpg 1468w"],
          "sizes": "(max-width: 1468px) 100vw, 1468px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1464px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "63.74999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe3uUoP/xAAVEAEBAAAAAAAAAAAAAAAAAAABIP/aAAgBAQABBQIr/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGRAAAgMBAAAAAAAAAAAAAAAAABEBECFB/9oACAEBAAE/IcRqYq6Ov//aAAwDAQACAAMAAAAQoM//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAdEAEAAQMFAAAAAAAAAAAAAAABABARQSExYXGh/9oACAEBAAE/ENKEZAQu2CBwSwLuFuPaf//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Big Mario Marketing Slack 2",
          "title": "Big Mario Marketing Slack 2",
          "src": "/static/21aad7f5a9e65a0ffb8d107a34dc6d49/2cc7d/big-mario-slack-convo-2.jpg",
          "srcSet": ["/static/21aad7f5a9e65a0ffb8d107a34dc6d49/a6407/big-mario-slack-convo-2.jpg 400w", "/static/21aad7f5a9e65a0ffb8d107a34dc6d49/a24e6/big-mario-slack-convo-2.jpg 800w", "/static/21aad7f5a9e65a0ffb8d107a34dc6d49/2cc7d/big-mario-slack-convo-2.jpg 1464w"],
          "sizes": "(max-width: 1464px) 100vw, 1464px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1446px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "67.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3amgD//EABUQAQEAAAAAAAAAAAAAAAAAAAEg/9oACAEBAAEFAiv/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAXEAADAQAAAAAAAAAAAAAAAAABECEx/9oACAEBAAE/ISlYGP8A/9oADAMBAAIAAwAAABATz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EAB0QAAIBBAMAAAAAAAAAAAAAAAERABAhQWFRkaH/2gAIAQEAAT8QAAseYxx5HqECWAynFs90/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Big Mario Marketing Slack 3",
          "title": "Big Mario Marketing Slack 3",
          "src": "/static/e8d79df77167bf36e1cc3c882d77fe8b/6a62d/big-mario-slack-convo-3.jpg",
          "srcSet": ["/static/e8d79df77167bf36e1cc3c882d77fe8b/a6407/big-mario-slack-convo-3.jpg 400w", "/static/e8d79df77167bf36e1cc3c882d77fe8b/a24e6/big-mario-slack-convo-3.jpg 800w", "/static/e8d79df77167bf36e1cc3c882d77fe8b/6a62d/big-mario-slack-convo-3.jpg 1446w"],
          "sizes": "(max-width: 1446px) 100vw, 1446px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1460px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "64.99999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHcsthIB//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEAAQUCX//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABkQAAMBAQEAAAAAAAAAAAAAAAABIREQMf/aAAgBAQABPyFPaQwTlrF5z//aAAwDAQACAAMAAAAQcM//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAbEAEAAwEAAwAAAAAAAAAAAAABABEhMUFxwf/aAAgBAQABPxAtOPhgMv7AVkTQE68m7Svc/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Big Mario Marketing Slack 4",
          "title": "Big Mario Marketing Slack 4",
          "src": "/static/c2c1e5b116ebb434132cb6da495e4ebd/8e5dd/big-mario-slack-convo-4.jpg",
          "srcSet": ["/static/c2c1e5b116ebb434132cb6da495e4ebd/a6407/big-mario-slack-convo-4.jpg 400w", "/static/c2c1e5b116ebb434132cb6da495e4ebd/a24e6/big-mario-slack-convo-4.jpg 800w", "/static/c2c1e5b116ebb434132cb6da495e4ebd/8e5dd/big-mario-slack-convo-4.jpg 1460w"],
          "sizes": "(max-width: 1460px) 100vw, 1460px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1462px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "62%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEDBAX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHR15VGIP/EABkQAAIDAQAAAAAAAAAAAAAAAAIQAQMEMf/aAAgBAQABBQIrdEEh4v/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABgQAAIDAAAAAAAAAAAAAAAAAAEhAxIg/9oACAEBAAY/AjWNApa//8QAGRAAAgMBAAAAAAAAAAAAAAAAARARITEA/9oACAEBAAE/IQLLEtsPHhi//9oADAMBAAIAAwAAABDgz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABgQAQADAQAAAAAAAAAAAAAAAAEAESEQ/9oACAEBAAE/EBBI7OmN3DmACioBEqf/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Big Mario Marketing Slack 5",
          "title": "Big Mario Marketing Slack 5",
          "src": "/static/2c036c7f3600ce1137fd9b8265618c40/fa99c/big-mario-slack-convo-5.jpg",
          "srcSet": ["/static/2c036c7f3600ce1137fd9b8265618c40/a6407/big-mario-slack-convo-5.jpg 400w", "/static/2c036c7f3600ce1137fd9b8265618c40/a24e6/big-mario-slack-convo-5.jpg 800w", "/static/2c036c7f3600ce1137fd9b8265618c40/fa99c/big-mario-slack-convo-5.jpg 1462w"],
          "sizes": "(max-width: 1462px) 100vw, 1462px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1462px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "59.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECBAX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHb0ZsQw//EABgQAAMBAQAAAAAAAAAAAAAAAAECEAQx/9oACAEBAAEFAjqCmLyf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRAAAQUAAAAAAAAAAAAAAAAAAQIREiAh/9oACAEBAAY/AiIKwtb/xAAYEAADAQEAAAAAAAAAAAAAAAAAAREQMf/aAAgBAQABPyGnfoKUapv/2gAMAwEAAgADAAAAEPPP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGxAAAwACAwAAAAAAAAAAAAAAAAERIVExYXH/2gAIAQEAAT8QStB0EsxyroTHgZRlzlkWiLR//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Big Mario Marketing Slack 6",
          "title": "Big Mario Marketing Slack 6",
          "src": "/static/e05c34a321d06efb6e26a4b2b5d5a53c/fa99c/big-mario-slack-convo-6.jpg",
          "srcSet": ["/static/e05c34a321d06efb6e26a4b2b5d5a53c/a6407/big-mario-slack-convo-6.jpg 400w", "/static/e05c34a321d06efb6e26a4b2b5d5a53c/a24e6/big-mario-slack-convo-6.jpg 800w", "/static/e05c34a321d06efb6e26a4b2b5d5a53c/fa99c/big-mario-slack-convo-6.jpg 1462w"],
          "sizes": "(max-width: 1462px) 100vw, 1462px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1436px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "71.50000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEGAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHs6zFOMYf/xAAaEAEAAQUAAAAAAAAAAAAAAAABAAMQETEz/9oACAEBAAEFAhMWqdTU/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGBAAAgMAAAAAAAAAAAAAAAAAARADMXL/2gAIAQEABj8CsByaL//EABsQAQACAgMAAAAAAAAAAAAAAAEQEQAhMUFh/9oACAEBAAE/IR9J7KC0s2ZwR//aAAwDAQACAAMAAAAQ8w//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAcEAEAAQQDAAAAAAAAAAAAAAABEQAQIaExUbH/2gAIAQEAAT8QJCjBEEboO2yxxAQZiU8rXLf/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Big Mario Marketing Slack 7",
          "title": "Big Mario Marketing Slack 7",
          "src": "/static/94d29cc15050bbef27c69b4ef4b07097/06526/big-mario-slack-convo-7.jpg",
          "srcSet": ["/static/94d29cc15050bbef27c69b4ef4b07097/a6407/big-mario-slack-convo-7.jpg 400w", "/static/94d29cc15050bbef27c69b4ef4b07097/a24e6/big-mario-slack-convo-7.jpg 800w", "/static/94d29cc15050bbef27c69b4ef4b07097/06526/big-mario-slack-convo-7.jpg 1436w"],
          "sizes": "(max-width: 1436px) 100vw, 1436px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1400px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "47.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB0bytEgV//8QAFhAAAwAAAAAAAAAAAAAAAAAAEyAi/9oACAEBAAEFAjWv/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFxAAAwEAAAAAAAAAAAAAAAAAAQISIP/aAAgBAQAGPwKQuv/EABoQAAICAwAAAAAAAAAAAAAAAAEQETFBUWH/2gAIAQEAAT8hwcjc9Yp//9oADAMBAAIAAwAAABB8D//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/EGf/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPxAn/8QAFxABAQEBAAAAAAAAAAAAAAAAAREhEP/aAAgBAQABPxATKSlIchydIYJ3/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Big Mario Marketing Slack 8",
          "title": "Big Mario Marketing Slack 8",
          "src": "/static/a69508828a1d65288a150bb74262ae75/5814a/big-mario-slack-convo-8.jpg",
          "srcSet": ["/static/a69508828a1d65288a150bb74262ae75/a6407/big-mario-slack-convo-8.jpg 400w", "/static/a69508828a1d65288a150bb74262ae75/a24e6/big-mario-slack-convo-8.jpg 800w", "/static/a69508828a1d65288a150bb74262ae75/5814a/big-mario-slack-convo-8.jpg 1400w"],
          "sizes": "(max-width: 1400px) 100vw, 1400px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1414px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "57.00000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQCBf/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAABsi0K6ChZ/8QAHRAAAgIBBQAAAAAAAAAAAAAAAgMBEQASEyIkM//aAAgBAQABBQI2RurvSkeBx2E+4xWf/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEx/9oACAEDAQE/AdR//8QAFhEBAQEAAAAAAAAAAAAAAAAAEQAx/9oACAECAQE/AQyb/8QAHRAAAgIBBQAAAAAAAAAAAAAAAAIBEnERIkFRkv/aAAgBAQAGPwJt462mW6tJz61GyMTk/8QAHRABAAICAgMAAAAAAAAAAAAAAQARITFBcYGhsf/aAAgBAQABPyEBcLKzjEBRjb0vMY225UF2r5CNmbB9kJEKI//aAAwDAQACAAMAAAAQsN//xAAXEQEBAQEAAAAAAAAAAAAAAAABEQAh/9oACAEDAQE/ELQOReb/xAAXEQEBAQEAAAAAAAAAAAAAAAABACER/9oACAECAQE/EMVgubf/xAAdEAEAAwABBQAAAAAAAAAAAAABABEhUTFBYXGx/9oACAEBAAE/EDDSil0s8TUKahQdqvk6RMXXd4g7vyMwcTrsVg0Ft2wCUGA9E//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Big Mario Marketing Slack 9",
          "title": "Big Mario Marketing Slack 9",
          "src": "/static/0d5ccc65f44ca73ff404ac5c8b4eb2a3/a375c/big-mario-slack-convo-9.jpg",
          "srcSet": ["/static/0d5ccc65f44ca73ff404ac5c8b4eb2a3/a6407/big-mario-slack-convo-9.jpg 400w", "/static/0d5ccc65f44ca73ff404ac5c8b4eb2a3/a24e6/big-mario-slack-convo-9.jpg 800w", "/static/0d5ccc65f44ca73ff404ac5c8b4eb2a3/a375c/big-mario-slack-convo-9.jpg 1414w"],
          "sizes": "(max-width: 1414px) 100vw, 1414px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1440px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "46.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAJABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEDAgX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHt0nsYw//EABcQAAMBAAAAAAAAAAAAAAAAAAEQMQL/2gAIAQEAAQUCzEIv/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGRAAAgMBAAAAAAAAAAAAAAAAABABESEx/9oACAEBAAE/IcC1yIX/2gAMAwEAAgADAAAAEDMP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGRABAAMBAQAAAAAAAAAAAAAAAQAQQRGh/9oACAEBAAE/EAgOQ6yvFN1//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Big Mario Marketing Slack 10",
          "title": "Big Mario Marketing Slack 10",
          "src": "/static/fb989b9d19deb30782929f4c640f663a/33266/big-mario-slack-convo-10.jpg",
          "srcSet": ["/static/fb989b9d19deb30782929f4c640f663a/a6407/big-mario-slack-convo-10.jpg 400w", "/static/fb989b9d19deb30782929f4c640f663a/a24e6/big-mario-slack-convo-10.jpg 800w", "/static/fb989b9d19deb30782929f4c640f663a/33266/big-mario-slack-convo-10.jpg 1440w"],
          "sizes": "(max-width: 1440px) 100vw, 1440px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><strong parentName="p">{`We’ll check in as the data rolls in...but first you gotta guess - which ad do
you predict will perform best?`}</strong></p>
    <PostFooter nextButton={{
      text: postMap.spoiler_alert_targeting_tai_lopez_s_audience_is_working.label,
      slug: slugify(postMap.spoiler_alert_targeting_tai_lopez_s_audience_is_working.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      